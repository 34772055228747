<template>
	<Map ref="map" />
</template>

<script>
import Map from '@/components/map/Map.vue'

export default {
	name: 'MapView',
	components: { Map },
	props: {
		app: Object
	},
	mounted() {
		let loggedInUserData = this.$store.getters.getLoggedInUserData;
		let fic = !!(loggedInUserData && loggedInUserData.types && loggedInUserData.types.includes("FIC"));
		this.$refs.map.openWebSocket(this.$rest, () => {
			this.$refs.map.registerToFlyingObjectUpdates();
		});

		if(fic && loggedInUserData.airportUsers && loggedInUserData.airportUsers.length > 0) {
			let ap = loggedInUserData.airportUsers[0].airport;
			if(ap) {
				this.$refs.map.moveTo(ap.latitude, ap.longitude, 14);
			}
		}
	}
}
</script>

<style>
</style>
